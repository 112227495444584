<script setup>
import FooterComponent from '@/components/FooterComponent.vue';
import CupomLogo from '@/assets/img/logo/logo.png';
</script>

<script>
export default {
  data() {
    return {
      imgCupomLogo: CupomLogo,
    }
  },
  computed: {
    isOfertaRoute() {
      return this.$route.path.startsWith("/oferta/");
    }
  }
}
</script>

<template>
  <div class="flex grow flex-col bg-[#fff9da]">
    <div class="flex flex-1 flex-col">

      <!-- Content -->
      <main>
        <section class="max-w-7xl mx-auto mt-8 mb-12 flex flex-col justify-center grow px-4 lg:px-6">

          <div class="mx-auto max-w-2xl text-center mt-4">
            <router-link :to="'/'">
              <img :src="imgCupomLogo" class="max-h-[60px]" />
            </router-link>
            <p class="mt-2 text-sm tracking-wide leading-8 text-gray-600">Ofertas selecionadas</p>
          </div>

          <router-view />
        </section>
      </main>

      <!-- Footer -->
      <FooterComponent />

      <div v-if="!isOfertaRoute" class="py-5">
      </div>

    </div>
    <div v-if="!isOfertaRoute" class="fixed bottom-0 w-full flex items-center justify-start bg-gray-50 text-gray-500 text-xs px-4 py-3">
      Quando você compra por meio de links em nosso site podemos ganhar uma comissão de afiliados sem nenhum custo para você.
    </div>
  </div>
</template>